import FingerprintAPI from "assets/js/fingerprintModule.js";
import { getAuthToken } from "services/authentication";

export const requestInterceptor = (config) => {
    var fingerprint = new FingerprintAPI();
    config.headers["AUTH-TOKEN"] = getAuthToken();
    config.headers["DEVICE-ID"] = fingerprint.getDeviceID();
    config.headers['X-DJANGO-TIMEZONE'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let user = JSON.parse(localStorage.getItem("persist:root"));
    user = user && JSON.parse(user?.user);
    config.headers["User-Id"] = user?.user_id || null;
    config.headers["User-Email"] = user?.email || null;
    config.headers["User-Role"] = user?.role?.slug || null;
    return config;
};
