export const HOME = "/";
export const LOG_IN = "/login";
export const TERMS_AND_CONDITIONS = "/term-and-conditions";
export const PRODUCTS = "/products";
export const PRODUCT_DETAIL = "/products/:id";

export const NEW_ORDER = "/new-order";
export const MY_ORDERS = "/my-orders";
export const MY_ORDERS_MENU = "/my-orders/:menu";
export const MY_ORDER_ID = "/my-orders/:menu/:id/:order_id";
export const INVOICE_DETAILS = "/my-orders/invoices/:id";
export const NEW_UPDATES = "/new-updates";
export const RMA_ROUTE = "/rma";
export const MY_ACCOUNT = "/my-account";
export const CONTACT_US = "/contact-us";
export const SIGNUP = "/sign-up";
export const SIGNUP_VENDORS = "/sign-up-vendors";
export const PRIVACY_NOTICE = "/privacy-notice";
export const MY_ACCOUNT_EDIT = "/my-account/edit-profile";
export const MY_ACCOUNT_CHANGE_PASS = "/my-account/change-password";
export const MY_CART = "/my-cart";
export const MOBILE_CHECKS = "/mobile-checks";
export const MY_ACCOUNT_RESET_PASS = "/my-account/reset-password";
export const RMA_REQ = "/rma/request";
export const RMA_VIEW = "/rma/view/:id";
export const NOTIFICATION = "/notification";
export const ITEM_VIEW = "/:location/view-item/:id/:type";
export const FORGET_PASSWORD = "/forget-password";

export const MANAGE_INVENTORIES = "/manage-inventories";
export const MANAGE_INVENTORIES_TABS = "/manage-inventories/:tab/:status?";

export const MANAGE_INVENTORIES_ADD_PRODUCT = "/manage-inventories/add-product";

export const WISHLIST = "/wishlist";
export const PAYMENT_METHODS = "/payment-methods";

export const MY_REVIEWS = "/my-reviews";
export const CUSTOMER_VERIFICATION_PROCESS = "/customer-verification-process";