import format from "number-format.js";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { NEW_STORAGE_ENABLED_KEY } from "constants/app-constants";

export const nullValues = (value) => {
  return value ? value : "- - -";
};

export function priceFormatter(value) {
  if (value || value === 0) {
    let valueLocal =
      value &&
      value
        ?.toString()
        ?.replaceAll("$", "")
        ?.replaceAll(" ", "")
        ?.replaceAll(",", "");
    let formattedValue = format("$#,##0.00", valueLocal);
    return formattedValue;
  } else {
    return "- - -";
  }
}

export function numberFormatter(value, isFloat = false) {
  let valueLocal = value && value?.toString();
  if (isFloat) {
    return format("#,##0.00", valueLocal);
  } else {
    return format("#,##0.", valueLocal);
  }
}

export function phoneNumberFormatter(value) {
  try {
    if (value) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parse(value?.startsWith("+") ? value : `+${value}`, undefined);
      const formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
      return formattedNumber;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }

}

export function getDeviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

export function getBrowser() {
  var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: M[1]
  };
}


export function getItemURI(data) {
  return encodeURIComponent(data?.upc);
}

// creates a query string from query object
export function createQueryString(queryObject = {}) {
  let queryString = Object.keys(queryObject)
    .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
    .map((key) => {
      return Array.isArray(queryObject[key]) ? queryObject[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
    }).join('&');
  return queryString ? `?${queryString}` : "";
}

// turns query string back into an object
export function queryStringToObject(queryString = "", options = {}) {
  let queryObject = {};
  queryString && queryString.replace('?', '').split('&').forEach((itemString) => {
    const itemStringLocal = decodeURIComponent(itemString);
    let [itemKey, itemValue] = itemStringLocal.split("=");
    if (Object.prototype.hasOwnProperty.call(options, itemKey)) {
      if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
        queryObject[itemKey] = [];
      }
      Array.isArray(options[itemKey]) ? queryObject[itemKey].push(itemValue) : queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue;
    }
  });
  return queryObject;
}


export function getAbsoluteHeight(el) {
  // Get the DOM Node if you pass in a string
  el = (typeof el === 'string') ? document.getElementById(el) : el;
  if (!el) {
    return;
  }
  const styles = window.getComputedStyle(el);
  const margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
}

export function getTerm(termParam) {
  let term;
  switch (termParam) {
    case "NET_15":
      term = "Net 15";
      break;
    case "NET_30":
      term = "Net 30";
      break;
    default:
      term = "";
      break;
  }

  return term;
}

export function getCustomerShippingAddress(obj) {
  let address = "";

  let addressArray = [];

  if (obj?.primary_address_shipping) {
    addressArray.push(obj?.primary_address_shipping);
  }
  if (obj?.city_shipping) {
    addressArray.push(obj?.city_shipping);
  }
  if (obj?.state_shipping) {
    addressArray.push(obj?.state_shipping);
  }
  if (obj?.zip_code_shipping) {
    addressArray.push(obj?.zip_code_shipping);
  }

  if (addressArray.length > 0) {
    address = addressArray?.join(", ");
  }

  return address;

}

export const getOrderTotal = (data, shippingMethodAmount) => {
  let total = 0;
  data?.items?.forEach((item) => {
    total += parseFloat(item?.item?.price?.replace(/,/g, "").replace("$", "")) * item?.qty;
  });
  total += (shippingMethodAmount ?? 0);
  return total;
};


export const getInvoiceStatus = (status = "", isRetail) => {
  let statusLocal = status?.toLowerCase();
  if (isRetail) {
    switch (statusLocal) {
      case "sales order":
        statusLocal = "pending";
        break;
      case "completed":
        statusLocal = "shipped";
        break;
      default:
        break;
    }
  }
  return statusLocal?.toUpperCase();
};

export const getCombinedItems = (invoiceDetails) => {
  let combinedItems = [];
  invoiceDetails?.details?.forEach(function (item) {
    let existing = combinedItems.filter(function (v, i) {
      return v?.key === item?.key && parseFloat(v?.value?.price) === parseFloat(item?.value?.price);
    });
    if (existing.length) {
      let existingIndex = combinedItems?.indexOf(existing[0]);
      combinedItems[existingIndex] = {
        ...combinedItems[existingIndex],
        value: {
          ...combinedItems[existingIndex].value,
          qty: parseInt(combinedItems[existingIndex].value?.qty ?? 0) + parseInt(item?.value?.qty ?? 0)
        }
      };
      // combinedItems[existingIndex].value.qty = parseInt(combinedItems[existingIndex].value?.qty ?? 0) + parseInt(item?.value?.qty ?? 0);
    } else {
      if (typeof item?.value?.qty == 'string' || typeof item?.value?.qty == 'number')
        item.value.qty = parseInt(item?.value?.qty);
      combinedItems.push(item);
    }
  });
  return combinedItems;
};


export function getIsNewStorageEnabled() {
  return localStorage.getItem(NEW_STORAGE_ENABLED_KEY) === "1";
}

export function modifyFileKey(oldKey) {
  const isNewStorageEnabled = getIsNewStorageEnabled();
  const newKey = isNewStorageEnabled ? oldKey + "_new" : oldKey;
  return newKey;
}

export function convertBase64(file) {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}

export const validateArray = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};