import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "antd/dist/antd.less";
import { Switch, useLocation } from "react-router-dom";
import PublicRoute from "routes/public-routes";
import PrivateRoute from "routes/private-routes";
import ProtectedRoutes from "routes/protected-routes";
import { CONTACT_US, FORGET_PASSWORD, LOG_IN, HOME, PRIVACY_NOTICE, PRODUCTS, PRODUCT_DETAIL, SIGNUP, SIGNUP_VENDORS, TERMS_AND_CONDITIONS } from "constants/routes-constants";
import FingerprintAPI from "assets/js/fingerprintModule";
import { apiGet, apiPost } from "services/apis";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import { setBrands, setCategories, setIsBrandsLoaded, setIsCategoriesLoaded, setNotificationCount } from "reduxToolkit/appReducer.redux";
import "react-phone-input-2/lib/bootstrap.css";
import { setItemsCount } from "reduxToolkit/cartReducer.redux";
import { signOut } from "reduxToolkit/checkCredentials.redux";
import LoadingSpinner from "components/common/loading-spinner";

import DefaultLayout from "layout/DefaultLayout";

import Login from "pages/login/login";
import SignUp from "pages/signup";
import ForgetPassword from "pages/forget-password/forget-password";
import SignUpVendors from "pages/signup/signup-vendors";
import NewOrder from "pages/new-order";
import ProductDetails from "components/common/product-details";
import { lazyWithRetry } from "services/lazyWithRetry";
import { NEW_STORAGE_ENABLED_KEY } from "constants/app-constants";

const TermsAndConditions = lazyWithRetry(() => import("pages/terms-and-conditions"));
const Home = lazyWithRetry(() => import("pages/home"));
const PrivacyNotice = lazyWithRetry(() => import("pages/privacy-notice"));
const ContactUs = lazyWithRetry(() => import("pages/contact-us"));

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const credentials = useSelector((state) => state?.credentials);

  const [isHeartBeatLoaded, setIsHeartBeatLoaded] = useState(false);

  useEffect(() => {
    document.title = "Emolo";
    let urlFirstPath =
      location.pathname.split("/")
        ?.map((word, index) => {
          if (index === 1) {
            word = word.replaceAll("-", " ");
          }
          return word && " - " + word.charAt(0).toUpperCase() + word.slice(1);
        })
        ?.join(" ");
    if (urlFirstPath) {
      document.title = document.title + urlFirstPath;
    }
  }, [location.pathname]);

  useEffect(() => {
    new FingerprintAPI(); //initialize device_id
    callGetCategories();
    callGetFeatureFlags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callGetCategories = () => {
    apiGet("/category/get_categories")
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          dispatch(setCategories(res.data.payload.categories));
        }
      })
      .finally(() => {
        dispatch(setIsCategoriesLoaded(true));
        apiGet("/brand/get_store_brands").then((res) => {
          if (res.data.status === "SUCCESS") {
            dispatch(setBrands(res.data.payload.brands));
          }
        }).finally(() => {
          dispatch(setIsBrandsLoaded(true));

        });
      });
  };

  const callGetFeatureFlags = () => {
    apiGet("/get_features")
      .then((res) => {
        if (res?.data?.status === "SUCCESS") {
          const isNewStorageEnabled = res?.data?.payload?.enable_new_storage ? "1" : "0";
          localStorage.setItem(NEW_STORAGE_ENABLED_KEY, isNewStorageEnabled);
        }
      });
  };

  useEffect(() => {
    if (credentials?.isLoggedIn) {
      setIsHeartBeatLoaded(false);
      apiGet("heartbeat_2")
        .finally(() => {
          setIsHeartBeatLoaded(true);
          apiPost("/cart/get_cart_count", {})
            .then((res) => {
              if (res?.data?.status === "SUCCESS") {
                dispatch(setItemsCount(res?.data?.payload?.cart_count));
              }
            });
          apiPost("/notification/get_notification_count", {})
            .then((res) => {
              if (res?.data?.status === "SUCCESS") {
                dispatch(setNotificationCount(res?.data?.payload?.notification_count));
              }
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  const onIdle = () => {
    if (credentials.isLoggedIn) {
      apiPost("/store_logout", {})
        .then((res) => {
          localStorage.clear();
          dispatch(signOut());
          window.location = LOG_IN;
          window.location.reload(false);
        });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 120, // 2 hours
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    syncTimers: 0,
  });

  return (
    <div>
      {!isHeartBeatLoaded && credentials.isLoggedIn ? (
        <LoadingSpinner height="90vh" />
      ) : (
        <Switch>
          {/* Restricted "true", means once logged in, user "WILL NOT" BE able to navigate to the public path */}

          <PublicRoute path={SIGNUP_VENDORS} exact={true} restricted={true} isPreventRoute={true}>
            <SignUpVendors />
          </PublicRoute>

          <DefaultLayout>
            <Suspense
              fallback={
                <div>
                  <LoadingSpinner height="90vh" />
                </div>
              }
            >
              <Switch>
                {/* Restricted "false", means once logged in, user "WILL" BE able to navigate to the public path */}

                <PublicRoute path={FORGET_PASSWORD} exact={true} restricted={true}>
                  <ForgetPassword />
                </PublicRoute>

                <PublicRoute path={LOG_IN} exact={true} restricted={true} >
                  <Login />
                </PublicRoute>

                <PublicRoute path={SIGNUP} exact={true} restricted={true} >
                  <SignUp />
                </PublicRoute>

                <PublicRoute path={HOME} exact={true} restricted={false}>
                  <Home />
                </PublicRoute>

                <PublicRoute path={PRODUCTS} exact={true} restricted={false}>
                  <NewOrder />
                </PublicRoute>

                <PublicRoute path={PRODUCT_DETAIL} exact={true} restricted={false}>
                  <ProductDetails />
                </PublicRoute>

                <PublicRoute path={TERMS_AND_CONDITIONS} exact={true} restricted={false}>
                  <TermsAndConditions />
                </PublicRoute>

                <PublicRoute path={PRIVACY_NOTICE} exact={true} restricted={false} isPreventRoute={true}>
                  <PrivacyNotice />
                </PublicRoute>

                <PublicRoute path={CONTACT_US} exact={true} restricted={false}>
                  <ContactUs />
                </PublicRoute>

                <PrivateRoute path={HOME}>
                  <ProtectedRoutes />
                </PrivateRoute>

              </Switch>
            </Suspense>
          </DefaultLayout>
        </Switch>
      )}
    </div>
  );
}

export default App;
