import React, { useState } from "react";
import { Badge, Col, Dropdown, Row, Space } from "antd";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { LOG_IN, MY_CART } from "constants/routes-constants";

import { useMediaQuery } from "react-responsive";
import { DownOutlined } from "@ant-design/icons";
import { CartDiv } from "styles/globalStyling.styled";
import NavigationHeader from "components/header-components/navigation-header";
import AccountMenu from "components/header-components/account-menu";
import HeaderSearch from "components/header-components/header-search";
import MobileMenu from "components/mobile-menu";
import { getImageUrlSrc } from "services/apis";
import { modifyFileKey } from "services/helpers";

import ProfileIcon from "assets/icons/header/profile-placeholder.svg";
import EmoloLogo from "assets/icons/emolo-logo-horizontal.svg";
import CartIcon from "assets/icons/header/basket-cart-icon.svg";
import MobileMenuOpenIcon from "assets/icons/header/mobile-menu-open-icon.svg";
import VerificationIcon from "assets/icons/header/verification-icon.svg";

const AppHeader = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const credentials = useSelector((state) => state?.credentials);
  const cartItems = useSelector((state) => state?.cart?.items);
  const permissions = useSelector((state) => state?.app?.permissions);

  const [isLanMenuOpen, setIsLanMenuOpen] = useState(false);

  const DrawerButton = () => {
    return (
      <div className="d-flex">
        <img
          className="cursor-pointer"
          src={MobileMenuOpenIcon}
          alt="User Icon"
          style={{
            width: "32px",
            height: "32px",
            objectFit: "contain",
          }}
          onClick={() => {
            setIsLanMenuOpen(true);
          }}
        />
      </div>
    );
  };

  const CartBadge = () => {
    return (
      <Badge
        size={isMobile ? "small" : "default"}
        className={`cursor-pointer`}
        count={cartItems ?? 0}
        overflowCount={999}
        onClick={() => {
          history.push(MY_CART);
        }}
        showZero={true}
        color="#474747"
        style={{ padding: isMobile ? "2px" : "", textAlign: "center", height: "18px", minWidth: "18px" }}
      >
        <img
          src={CartIcon}
          alt="emolo logo"
          className={!isMobile && "me-2"}
          style={{
            width: "32px",
            height: "32px",
            border: "1px solid #DEDEDE",
            padding: "1px",
            borderRadius: "50%"
          }}
        />
      </Badge>
    );
  };

  return (
    <header
      className="mb-0 m-0 p-0"
      style={{
        position: "sticky",
        top: "0",
        zIndex: "17",
        background: "white",
      }}
    >
      <Row className={`padding-main ${isMobile ? "d-flex align-items-center justify-content-between" : "py-2"}`} style={{ paddingTop: "9px", paddingBottom: "9px" }}>
        <Col xs={12} sm={12} md={7} lg={6} xl={6} >
          <Link to="/" style={{ width: "fit-content" }}>
            <img src={EmoloLogo} alt="emolo logo" style={{ width: isMobile ? "120px" : "" }} />
          </Link>
        </Col>
        {!isMobile && (
          <Col xs={6} sm={6} md={6} lg={12} xl={12} className="mx-auto mt-1">
            <HeaderSearch />
          </Col>
        )}

        <Col xs={12} sm={12} md={6} lg={6} xl={6} className={!isMobile && "pt-2 text-end justify-content-end ms-auto"} >
          <div className={isMobile ? "d-flex gap-3 my-1 justify-content-end" : "d-flex ms-auto text-end justify-content-end gap-4"}>

            <div>
              <Dropdown
                overlay={credentials?.isLoggedIn ? <AccountMenu /> : <></>}
                trigger={["click"]}
                onOpenChange={(e) => {
                  if (!credentials?.isLoggedIn) {
                    history.push(LOG_IN);
                  }
                }}
                placement="bottomCenter"
              >
                <Space className="d-flex cursor-pointer">
                  <img
                    src={credentials?.isLoggedIn ? getImageUrlSrc(credentials?.user?.[modifyFileKey("image")]) : ProfileIcon}
                    alt="User Icon"
                    style={{
                      width: "32px",
                      height: "32px",
                      objectFit: "contain",
                      borderRadius: credentials?.isLoggedIn ? "50%" : "",
                      border: credentials?.isLoggedIn ? "1px solid var(--primary-color)" : "",
                      padding: credentials?.isLoggedIn ? "1px" : ""
                    }}
                  />
                  {
                    !isMobile && (
                      <>
                        <div className="text-start">
                          <div className="bold-14">Your Account</div>
                          {
                            credentials?.isLoggedIn
                              ? <div className="regular-14">
                                <span>{credentials?.user?.contact_name}</span>
                                {
                                  credentials?.user?.verified && (
                                    <span>
                                      <img
                                        style={{ width: "14px" }}
                                        src={VerificationIcon}
                                        alt="verification"
                                        className={`ms-1 mb-1`}
                                      />
                                    </span>
                                  )
                                }

                              </div>
                              : <div className="regular-14">Hello! Sign in</div>
                          }
                        </div>
                        {credentials?.isLoggedIn && <DownOutlined size="sm" />}
                      </>
                    )
                  }
                </Space>
              </Dropdown>
            </div>
            {
              credentials?.isLoggedIn && permissions?.view_cart &&
              (
                isMobile
                  ? <CartBadge />
                  : <CartDiv
                    className={`cursor-pointer bg-orange-primary ${isMobile ? "py-1" : "py-2"}  px-3`}
                    style={{ borderRadius: "120px" }}
                    onClick={() => {
                      history.push(MY_CART);
                    }}
                  >
                    <CartBadge />
                    <span className="bold-14 white-text-color ps-1">Cart</span>
                  </CartDiv>

              )

            }

            {isMobile && <DrawerButton />}
          </div>
        </Col>
      </Row>

      {
        isMobile ? (
          <div className={"bg-primary py-3 px-2"}>
            <HeaderSearch />
            <MobileMenu
              isLanMenuOpen={isLanMenuOpen}
              setIsLanMenuOpen={setIsLanMenuOpen}
            />
          </div>
        ) : (
          <NavigationHeader />
        )
      }
    </header >
  );
};

export default AppHeader;
